import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/pelaporan',
      name: 'pelaporan',
      component: () => import('@/views/laporan/StatsPage.vue'),
      meta: {
        pageTitle: 'Pelaporan',
        breadcrumb: [
          {
            text: 'Pelaporan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pencetakan/status',
      name: 'pencetakan-status',
      component: () => import('@/views/pencetakan/StatusCetakList.vue'),
      meta: {
        pageTitle: 'Status Cetak',
        breadcrumb: [
          {
            text: 'Status Cetak',
            active: true,
          },
        ],
        action: 'write',
        resource: 'pencetakan',
      },
    },
    {
      path: '/pencetakan/ready-cetak',
      name: 'pencetakan-ready-cetak',
      component: () => import('@/views/pencetakan/ReadyCetakList2.vue'),
      meta: {
        pageTitle: 'Pencetakan',
        breadcrumb: [
          {
            text: 'Ready Cetak',
            active: true,
          },
        ],
        action: 'read',
        resource: 'pencetakan',
      },
    },
    {
      path: '/pencetakan/selesai-cetak',
      name: 'pencetakan-selesai-cetak',
      component: () => import('@/views/pencetakan/SelesaiCetakList.vue'),
      meta: {
        pageTitle: 'Pencetakan',
        breadcrumb: [
          {
            text: 'Selesai Cetak',
            active: true,
          },
        ],
        action: 'read',
        resource: 'pencetakan',
      },
    },
    {
      path: '/pencetakan/batal-cetak',
      name: 'pencetakan-batal-cetak',
      component: () => import('@/views/pencetakan/BatalCetakList.vue'),
      meta: {
        pageTitle: 'Pencetakan',
        breadcrumb: [
          {
            text: 'Batal Cetak',
            active: true,
          },
        ],
        action: 'read',
        resource: 'pencetakan',
      },
    },
    {
      path: '/pencetakan/list',
      name: 'pencetakan-list',
      component: () => import('@/views/pencetakan/ReadyCetakList.vue'),
      meta: {
        pageTitle: 'Pencetakan',
        breadcrumb: [
          {
            text: 'Pencetakan',
            active: true,
          },
        ],
        action: 'read',
        resource: 'pencetakan',
      },
    },
    {
      path: '/pencetakan/stats',
      name: 'stats',
      component: () => import('@/views/pencetakan/Stats.vue'),
      meta: {
        pageTitle: 'Pencetakan',
        breadcrumb: [
          {
            text: 'Statistik',
            active: true,
          },
        ],
        action: 'read',
        resource: 'pencetakan',
      },
    },
    {
      path: '/pencetakan/stats/detail',
      name: 'stats-detail',
      component: () => import('@/views/pencetakan/StatsDetail.vue'),
      meta: {
        pageTitle: 'Pencetakan',
        breadcrumb: [
          {
            text: 'Stats',
            to: '/pencetakan/stats',
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
        action: 'read',
        resource: 'pencetakan',
      },
    },
    {
      path: '/pencetakan/stats/history/detail',
      name: 'stats-history-detail',
      component: () => import('@/views/pencetakan/StatsHistoryDetail.vue'),
      meta: {
        pageTitle: 'Pencetakan',
        breadcrumb: [
          {
            text: 'Stats',
            to: '/pencetakan/stats',
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
        action: 'read',
        resource: 'pencetakan',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/akun/Login.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/akun/verifikasi-biodata',
      name: 'akun-verifikasi-biodata',
      component: () => import('@/views/akun/AkunVerifikasiBiodata.vue'),
      meta: {
        pageTitle: 'Verifikasi Akun',
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/akun/register',
      name: 'akun-register',
      component: () => import('@/views/akun/Register.vue'),
      meta: {
        pageTitle: 'Registrasi',
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/akun/forgot-password',
      name: 'akun-forgot-password',
      component: () => import('@/views/akun/ResetPassword.vue'),
      meta: {
        pageTitle: 'Lupa Password',
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/database-advokat',
      name: 'biodata-list',
      component: () => import('@/views/hap/biodata/BiodataList.vue'),
      meta: {
        action: 'read',
        resource: 'list-biodata',
        pageTitle: 'Database Advokat',
        breadcrumb: [
          {
            text: 'Home',
            to: '/',
          },
        ],
      },
    },
    {
      path: '/probono/keanggotaan/laporan',
      name: 'probono-laporan-keanggotaan',
      component: () => import('@/views/probono/ProbonoList.vue'),
      meta: {
        action: 'read',
        resource: 'list-biodata',
        pageTitle: 'Probono Laporan',
        breadcrumb: [
          {
            text: 'Home',
            to: '/',
          },
        ],
      },
    },
    {
      path: '/cle/keanggotaan',
      name: 'cle-keanggotaan',
      component: () => import('@/views/cle/CleKeanggotaanList.vue'),
      meta: {
        action: 'read',
        resource: 'list-biodata',
        pageTitle: 'Probono Laporan',
        breadcrumb: [
          {
            text: 'Home',
            to: '/',
          },
        ],
      },
    },
    {
      path: '/biodata',
      name: 'biodata-new',
      component: () => import('@/views/hap/biodata/BiodataDetails.vue'),
      meta: {
        action: 'create',
        resource: 'biodata',
        pageTitle: 'Biodata Baru',
        breadcrumb: [
          {
            text: 'Database Anggota',
            to: '/database-advokat',
          },
          {
            text: 'Biodata Baru',
            active: true,
          },
        ],
      },
    },
    {
      path: '/biodata/:id',
      name: 'biodata-detil',
      component: () => import('@/views/hap/biodata/BiodataDetails.vue'),
      meta: {
        action: 'read',
        resource: 'biodata',
        pageTitle: 'Detail Biodata',
        breadcrumb: [
          {
            text: 'Database Anggota',
            to: '/database-advokat',
          },
          {
            text: 'Biodata',
            active: true,
          },
        ],
      },
    },
    {
      path: '/layanan-mandiri/biodata',
      name: 'mandiri-biodata',
      component: () => import('@/views/hap/biodata/BiodataDetails.vue'),
      meta: {
        action: 'read',
        resource: 'layanan-mandiri',
        pageTitle: 'Biodata',
        // breadcrumb: [
        //   {
        //     text: 'Database Anggota',
        //     to: '/database-advokat',
        //   },
        //   {
        //     text: 'Biodata',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/layanan-mandiri/permohonan',
      name: 'mandiri-list-permohonan',
      component: () => import('@/views/hap/permohonan/PermohonanList.vue'),
      meta: {
        action: 'read',
        resource: 'layanan-mandiri',
        pageTitle: 'Permohonan',
        // breadcrumb: [
        //   {
        //     text: 'Database Anggota',
        //     to: '/database-advokat',
        //   },
        //   {
        //     text: 'Biodata',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/layanan-mandiri/permohonan/:mode/:permohonan_id',
      name: 'mandiri-permohonan-detil',
      component: () => import('@/views/hap/permohonan/PermohonanDetails.vue'),
      meta: {
        pageTitle: 'Permohonan',
        breadcrumb: [
          {
            text: 'Permohonan',
            to: '/layanan-mandiri/permohonan',
          },
          {
            text: 'Detil',
            active: true,
          },
        ],
        action: 'read',
        resource: 'layanan-mandiri',
      },
    },
    {
      path:
        '/layanan-mandiri/permohonan/:mode/:jenis_permohonan_id/:biodata_id',
      name: 'mandiri-permohonan-pengajuan',
      component: () => import('@/views/hap/permohonan/PermohonanDetails.vue'),
      meta: {
        pageTitle: 'Permohonan Baru',
        breadcrumb: [
          {
            text: 'Permohonan',
            to: '/layanan-mandiri/permohonan',
          },
          {
            text: 'Pengajuan',
            active: true,
          },
        ],
        action: 'read',
        resource: 'layanan-mandiri',
      },
    },
    {
      path: '/layanan-mandiri/permohonan/terima-kasih',
      name: 'permohonan-terima-kasih',
      component: () => import('@/views/hap/permohonan/PermohonanThanks.vue'),
      meta: {
        pageTitle: 'Permohonan',
        action: 'read',
        resource: 'layanan-mandiri',
        breadcrumb: [
          {
            text: 'Permohonan',
            to: '/layanan-mandiri/permohonan',
          },
        ],
      },
    },
    {
      path: '/permohonan/list',
      name: 'permohonan-list',
      component: () => import('@/views/hap/permohonan/PermohonanList.vue'),
      meta: {
        action: 'read',
        resource: 'layanan-mandiri',
        pageTitle: 'Permohonan',
        // breadcrumb: [
        //   {
        //     text: 'Database Anggota',
        //     to: '/database-advokat',
        //   },
        //   {
        //     text: 'Biodata',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/under-maintenance',
      name: 'under-maintenance',
      component: () => import('@/views/error/UnderMaintenance.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/kta/:biodata_id/:mode',
      name: 'kta',
      component: () => import('@/views/hap/biodata/Kta.vue'),
      // component: () => import('@/views/error/UnderMaintenance.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/users',
      name: 'userlists',
      component: () => import('@/views/users/List.vue'),
      meta: {
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/add',
      name: 'user-add',
      component: () => import('@/views/users/Add.vue'),
      meta: {
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'Users',
            to: '/users',
          },
          {
            text: 'Add',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/:id',
      name: 'user-detil',
      component: () => import('@/views/users/Detail.vue'),
      meta: {
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'Lists',
            to: '/users',
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/akun/details',
      name: 'akun-edit',
      component: () => import('@/views/akun/Edit.vue'),
      meta: {
        pageTitle: 'Akun Pengguna',
        breadcrumb: [
          {
            text: 'Akun',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/faq/List.vue'),
      meta: {
        pageTitle: 'Faq',
        breadcrumb: [
          {
            text: 'faq',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('@/views/support/List.vue'),
      meta: {
        pageTitle: 'Bantuan',
        breadcrumb: [
          {
            text: 'bantuan',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/support/:id',
      name: 'support-detil',
      component: () => import('@/views/support/DetailSupport.vue'),
      meta: {
        pageTitle: 'Support',
        breadcrumb: [
          {
            text: 'Support',
            to: '/support',
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
        action: 'read',
        resource: 'biodata',
      },
    },
    {
      path: '/probono/laporan',
      name: 'probono-laporan',
      component: () => import('@/views/probono/LaporanList.vue'),
      meta: {
        pageTitle: 'Probono',
        breadcrumb: [
          {
            text: 'Laporan',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/probono/laporan/tambah',
      name: 'probono-laporan-tambah',
      component: () => import('@/views/probono/LaporanForm.vue'),
      meta: {
        pageTitle: 'Probono',
        breadcrumb: [
          {
            text: 'Laporan',
            to: '/probono/laporan',
          },
          {
            text: 'Tambah Laporan',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/probono/laporan/:id/:is_rekap',
      name: 'probono-laporan-detail',
      component: () => import('@/views/probono/LaporanList.vue'),
      meta: {
        pageTitle: 'Probono',
        breadcrumb: [
          {
            text: 'Laporan',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/probono/laporan/admin/tambah/:biodata_id',
      name: 'probono-laporan-tambah-id',
      component: () => import('@/views/probono/LaporanForm.vue'),
      meta: {
        pageTitle: 'Probono',
        breadcrumb: [
          {
            text: 'Laporan',
            to: '/probono/laporan',
          },
          {
            text: 'Tambah Laporan',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/probono/laporan/ubah/:id',
      name: 'probono-laporan-ubah',
      component: () => import('@/views/probono/LaporanForm.vue'),
      meta: {
        pageTitle: 'Probono',
        breadcrumb: [
          {
            text: 'Laporan',
            to: '/probono/laporan',
          },
          {
            text: 'Ubah Laporan',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/probono/layanan/form/:id/:biodata_id/:aksi',
      name: 'probono-layanan-form',
      component: () => import('@/views/probono/LayananForm.vue'),
      meta: {
        pageTitle: 'Probono',
        breadcrumb: [
          {
            text: 'Laporan',
            to: '/probono/laporan',
          },
          {
            text: 'Layanan',
            to: '/probono/layanan/:id',
            params: 3,
          },
          {
            text: 'Tambah Layanan',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/probono/layanan/:id/:biodata_id',
      name: 'probono-layanan',
      component: () => import('@/views/probono/LayananList.vue'),
      meta: {
        pageTitle: 'Probono',
        breadcrumb: [
          {
            text: 'Laporan',
            to: '/probono/laporan',
          },
          {
            text: 'Layanan',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/cle',
      name: 'cle',
      component: () => import('@/views/cle/CleList.vue'),
      meta: {
        pageTitle: 'CLE',
        breadcrumb: [
          {
            text: 'CLE',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/cle/tambah',
      name: 'cle-tambah',
      component: () => import('@/views/cle/CleForm.vue'),
      meta: {
        pageTitle: 'CLE',
        breadcrumb: [
          {
            text: 'CLE',
            to: '/cle',
          },
          {
            text: 'Tambah Kegiatan',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/cle/:id/:is_rekap',
      name: 'cle-biodataId',
      component: () => import('@/views/cle/CleList.vue'),
      meta: {
        pageTitle: 'CLE',
        breadcrumb: [
          {
            text: 'CLE',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/cle/tambah/:id',
      name: 'cle-tambah-id',
      component: () => import('@/views/cle/CleForm.vue'),
      meta: {
        pageTitle: 'CLE',
        breadcrumb: [
          {
            text: 'CLE',
            to: '/cle',
          },
          {
            text: 'Tambah Kegiatan',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/cle/admin/tambah/:biodata_id',
      name: 'cle-admin-tambah',
      component: () => import('@/views/cle/CleForm.vue'),
      meta: {
        pageTitle: 'CLE',
        breadcrumb: [
          {
            text: 'CLE',
            to: '/cle',
          },
          {
            text: 'Tambah Kegiatan',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    {
      path: '/cle/ubah/:id',
      name: 'cle-ubah',
      component: () => import('@/views/cle/CleForm.vue'),
      meta: {
        pageTitle: 'CLE',
        breadcrumb: [
          {
            text: 'CLE',
            to: '/cle',
          },
          {
            text: 'Ubah CLE',
            active: true,
          },
        ],
        action: 'read',
        resource: 'home',
      },
    },
    // {
    //   path: '/notifikasi',
    //   name: 'notifikasi',
    //   component: () => import('@/views/support/Notifikasi.vue'),
    //   meta: {
    //     pageTitle: 'Notifikasi',
    //     breadcrumb: [
    //       {
    //         text: 'notifikasi',
    //         active: true,
    //       },
    //     ],
    //     action: 'read',
    //     resource: 'home',
    //   },
    // },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Router Before Each hook for route protection
router.beforeEach((to, _, next) => {
  // const isLoggedIn = isUserLoggedIn()
  const isLoggedIn =
    localStorage.getItem('userData') && localStorage.getItem('accessToken')

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ! We updated login route name here from `auth-login` to `login` in starter-kit
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    if (to.name === 'home') {
      return next(getHomeRouteForLoggedInUser())
    }
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  return next()
})

export default router
